.matching {}

	.matching-card {
		border: 1px solid $r2-border;
		border-radius: $border-radius;
		overflow: hidden;
		margin-bottom: 16px;
	}
	
		.matching-card-header {
			background: $r2-table-hover-bg;
			padding: 24px;
			display: block;
			text-decoration: none;
			color: $body-color;
			
			&:hover,
			&:focus {
				text-decoration: none;
				color: $body-color;
			}
			
			h2 {
				margin-bottom: 24px;
			}
		}
		
		.matching-card-line {
			display: flex;
			flex-wrap: wrap;
			margin: 0 -8px;
			align-content: center;
		}
		
			.matching-card-line-item {
				margin: 0 8px;
				width: 160px;
				display: flex;
			}
			
			.matching-card-summary {
				background: white;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-radius: $border-radius;
				border: 1px solid $r2-border;
				padding: 16px;
				width: 100%;
				
				img {
					max-width: 100%;
					max-height: 90px;
				}
			}
			
					.matching-card-summary--gray {
						background: $r2-table-hover-bg;
					}
					
					.matching-card-summary--selected {
						background: $r2-blue-10;
					}
			
				.matching-card-summary-counter {
					font-size: 20px;
					font-weight: bold;
				}
				
				.matching-card-summary-title {
					color: $r2-purple;
					font-size: 16px;
				}
				
				.matching-card-summary-name {
					font-weight: bold;
					font-size: 16px;
					color: $body-color;
				}
				
		.matching-card-content {
			
		}
		
			.matching-card-content-item {
				padding: 15px 24px;
				border-top: 1px solid $r2-border;
				color: $body-color;
				text-decoration: none;
				display: block;
				
				&:hover,
				&:focus {
					text-decoration: none;
					color: $body-color;
				}
			}
			
					.matching-card-line-item--header {
						font-weight: bold;
					}
					
					.matching-card-line-item--value {
						text-align: center;
						justify-content: center;
						font-weight: bold;
					}