// Variables
@import 'variables';
// Bootstrap
@import '~bootstrap/scss/bootstrap';
//Font awesome
@import "~@fortawesome/fontawesome-pro/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-pro/scss/solid.scss";
@import "~@fortawesome/fontawesome-pro/scss/regular.scss";
@import "~@fortawesome/fontawesome-pro/scss/light.scss";
@import "~@fortawesome/fontawesome-pro/scss/brands.scss";
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker.min.css";
//DataTables
@import "~datatables.net-dt/css/jquery.dataTables";
//Select2
@import "node_modules/select2/dist/css/select2";
@import "node_modules/toastr/toastr";

:root {
    --check-color: #0096FF;
}

html,
body {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

.text-muted {
    color: #8181A5 !important;
}

@import "_partials/typography";
@import "_partials/buttons";
@import "_partials/tables";
@import "_partials/forms";

@import "_partials/app";
@import "_partials/header";
@import "_partials/sidebar";
@import "_partials/sidepanel";
@import "_partials/notificationBars";
@import "_partials/todoBar";

@import "_partials/grid";
@import "_partials/page";

@import "_partials/icons";
@import "_partials/logo";

@import "_partials/tags";
@import "_partials/badges";
@import "_partials/alerts";
@import "_partials/notifications";
@import "_partials/labels";
@import "_partials/conversations";

@import "_partials/modal";
@import "_partials/summary";

@import "_partials/dropdown";
@import "_partials/quicklinks";
@import "_partials/lists";
@import "_partials/note";

@import "_partials/box";
@import "_partials/card";
@import "_partials/customerCard";

@import "_partials/products";
@import "_partials/orders";
@import "_partials/contracts";
@import "_partials/offers";
@import "_partials/documents";
@import "_partials/matching";

@import "_partials/planner";

@import "_partials/disputes";

@import "_partials/rrule";
@import "_partials/maillog";
@import "_partials/toastr";
@import "_partials/log";

.modal-container {
    position: relative;

    .modal-backdrop, .modal {
        position: absolute;
    }
}

.tooltip-inner {
    max-width: 500px; /* set this to your maximum fitting width */
    width: inherit; /* will take up least amount of space */
}

.placeholder {
    border: 1px dotted black;
    margin: 1em 0;
    width: 100%;
    height: 50px;
}

.item-container {
    position: relative;
}

.ui-helper {
    width: 100%;
}

.sortable-placeholder {
    width: 100%;
    height: 50px;
    margin: .5rem 0 !important;
    border: 1px dashed rgb(222, 222, 227);
    background-color: 1px dashed rgb(250, 250, 255);
    border-radius: .25em;
}

tr.border-danger td {
    border-color: $r2-sys-red !important;
}

tr.border-warning td {
    border-color: $r2-sys-orange !important;
}

tr.border-success td {
    border-color: $r2-sys-green !important;
}

.strikethrough {
    text-decoration: line-through;
}

.livewire-styles {
    display: none;
}

// open date picker on field click
input[type="date"] {
    position: relative;
}

/* create a new arrow, because we are going to mess up the native one
see "List of symbols" below if you want another, you could also try to add a font-awesome icon.. */
input[type="date"]:after {
    font-weight: 300;
    font-family: 'Font Awesome 5 Pro';
    content: fa-content($fa-var-calendar);
    color: #555;
    padding: 0 5px;
    position: absolute;
    margin-left: 96px;
    margin-top: -22px;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: auto;
    height: auto;
    color: transparent;
    background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button {
    z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button {
    z-index: 1;
}

body.dark-mode {
    filter: invert(1) !important;
    background-color: #333 !important;
}

.cursor-not-allowed {
    cursor: not-allowed;
}

a[data-modal] {
    cursor: pointer !important;
    color: $link-color !important;

    &:hover {
        color: #0069b3 !important;
    }
}

.search-label {
    border: 1px solid #ececf2;
    border-radius: 4px;
    height: 40px;
    padding: 0 0 0 15px;
    font-weight: 700;
    margin: 0;

    input {
        font-family: inherit;
        font-size: inherit;
        line-height: inherit;

        overflow: visible;
        outline-offset: -2px;
        padding: 5px;
        background-color: transparent;
        margin: 0 0 0 3px;

        border: none;
        height: 38px;
        box-shadow: none;
        border-radius: 4px;
    }
}

