// Hide sidebar (webkit)
.app-sidebar::-webkit-scrollbar {
    width: 0px;
    background: transparent;
    display: none;
}
.app-sidebar::-webkit-scrollbar-thumb {
    background: #FF0000;
}

.app-sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 84px;
    transition: all 0.3s ease;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */

    .nav {
        flex-direction: column;
    }

    .nav-item {
        margin: 6px 0;
        width: 100%;
    }

    .nav-item:focus {
        outline: none;
    }

    .nav-link {
        position: relative;
        font-weight: bold;
        border-radius: 4px;
        color: $r2-purple;
        padding: 12px 15px 13px 15px;

        &:before {
            position: absolute;
            right: -16px;
            width: 3px;
            height: 100%;
            top: 0;
            content: "";
            background: $r2-blue;
            border-radius: 3px 0 0 3px;
            display: none;
        }

        &:hover,
        &:focus,
        &.active {
            background: $r2-blue-10;
            color: $r2-black;

            .nav-icon {
                color: $r2-blue;

                svg {
                    fill: $r2-blue;
                }
            }

            &:before {
                display: block;
            }
        }
    }

    .nav-link:focus {
        outline: none;
    }

    .nav-icon {
        position: absolute;
        top: 50%;
        left: 14px;
        margin-top: -12px;
        width: 20px;
        height: 20px;

        svg {
            fill: $r2-purple;
            max-height: 100%;
        }
    }

    .nav-text {
        overflow: hidden;
        max-width: 0;
        transition: max-width 0.3s ease;
        max-height: 22px;
        margin-left: 41px;
    }

    .nav-icon-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        position: relative;
        top: -2px;
    }

    .nav-treeview {
        max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease;
        display: flex;
        flex-direction: column;
        padding-top: 1px;
    }

    .treeview-item {
        padding-left: 40px;
        padding-top: 1px;
        padding-bottom: 1px;
    }

    .treeview-link {
        display: block;
        padding: 4px 10px 4px 16px;
        color: $r2-purple-40;
        border-radius: 4px;
        overflow: hidden;
        max-width: 0;
        max-height: 52px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: max-width 0.3s ease;

        &:hover,
        &:focus {
            text-decoration: none;
            background: $r2-blue-10;
            color: black;

        }
    }

    .has-treeview--active {
        .nav-treeview {
            max-height: 1000px;
        }
    }

    .dropdown-menu {
	    min-width: 233px;
    }
}

.app-sidebar-header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 14px 17px 24px;
    height: 92px;

}

	.app-sidebar-brand {
	    font-weight: bold;
	    color: $body-color;
	    font-size: 20px;
	    display: block;
	    white-space: nowrap;
	    overflow: hidden;
	    text-overflow: ellipsis;
		width: 100%;
		height: 54px;
		font-size: 0;
		position: relative;

		&:before {
			background: url("/images/logo-thinqsmart-mark.svg") center center no-repeat;
			background-size: contain;
			position: absolute;
			left: 0;
			top: 0;
			width: 50px;
			height: 54px;
			content: "";
			opacity: 1;
			transition: all 0.3s ease;
		}

		&:after {
			background: url("/images/logo-thinqsmart.svg") center top no-repeat;
			background-size: contain;
			position: absolute;
			left: 15px;
			top: 0;
			width: 120px;
			height: 54px;
			content: "";
			opacity: 0;
			transition: all 0.3s ease;
		}

	    &:hover,
	    &:focus {
	        text-decoration: none;
	        color: $body-color;
	    }

	    small {
	        display: block;
	        font-weight: normal;
	        font-size: 14px;
	        color: $r2-purple;
	        line-height: 1;
	    }
	}

		.app-sidebar-brand-company {
			position: absolute;
			bottom: 24px; left: 34px;
			right: 15px;
			opacity: 0;
			transition: all 0.3s ease;
		}

			.app-sidebar-brand-company-link {
			    white-space: nowrap;
			    overflow: hidden;
			    text-overflow: ellipsis;
			    color: $r2-purple;
			    display: block;

			    &:hover,
			    &:focus {
			        text-decoration: none;
			        outline: none;
			    }
			}


.app-user {}

.app-user-item {
    display: flex;
    position: relative;
    min-height: 30px;
    padding-left: 15px;
    margin-top: 10px;
}

.app-user-avatar {
    width: 52px;
    display: flex;
    justify-content: center;

    margin-right: 10px;
    position: absolute;
    left: 0;
    top: 0;
}

.app-user-avatar-image {
    width: 30px;
    height: 30px;
    border-radius: 50%;

}

.app-user-name {
    color: $r2-purple;
    font-weight: bold;
    max-width: 0px;
    overflow: hidden;
    transition: all 0.3s ease;
}
.app-user-role {
    color: $r2-purple;
    font-weight: unset;
    overflow: hidden;
    transition: all 0.3s ease;
}

.app-user,
.app-nav {
    margin: 0 -14px;
}

body.sidebarActive {
	.app-sidebar-brand {
		&:before {
			opacity: 0;
		}

		&:after {
			opacity: 1;
		}
	}

	.app-sidebar-brand-company {
		opacity: 1;
	}

	.app-sidebar {
		max-width: 265px;

		.nav-link {

		}

        .nav-text,
        .app-user-name,
        .treeview-link {
            max-width: 1000px;
        }
	}
	.app-main {
		padding-left: 295px;
	}

	.nav-text {
		margin-left: 41px;
	}
}

/*
	&:hover,
    &:focus {
        max-width: 1000px;
        box-shadow: -17px 0 94px rgba(0, 0, 0, 0.2);


        .nav-text,
        .app-user-name,
        .treeview-link {
            max-width: 1000px;
        }
    }
    */
