.note--trashed {
    opacity: .5;

    .notification-message {
        text-decoration: line-through;
    }

    .js-delete-note {
        display: none;
    }
}
