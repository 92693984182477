.activity {
    padding: 15px 20px;
    font-size: .7rem;
    color: #8989ab;

    .activity-header {
        color: #8181a5;
        font-size: 12px;
        margin-bottom: 3px;
        font-weight: bold;
    }
}
