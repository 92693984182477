#toast-container > .toast {
    box-shadow: rgba($secondary, .1) 0px 20px 25px -5px, rgb($secondary, .2) 0px 10px 10px -5px;
    background-image: none !important;
    border: 0;

    &:before {
        position: relative;
        font-family: 'Font Awesome 5 Pro';
        font-style: normal;
        font-weight: 400;
        float: left;
        font-size: 18px;
        line-height: 20px;
        margin-left: -1.6em;
        color: $white;
        padding-right: 0.5em;
        margin-right: 0.5em;
    }
}

#toast-container > div {
    border-radius: 10px;
    opacity: 1;
    padding: 15px 35px 15px 50px;

    &:hover {
        box-shadow: none;
    }
}

.toast-message {
    margin-left: 0.5em;
}

.toast-close-button {
    position: absolute;
    right: 15px;
    top: 9px;
}

.toast-close-button:hover, .toast-close-button:focus {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    opacity: 1;
}

#toast-container > .toast-warning {
    background-color: $warning;
    box-shadow: rgba($warning, .1) 0px 20px 25px -5px, rgb($warning, .2) 0px 10px 10px -5px !important;

    &:before {
        content: "\f071";
    }

    &:hover {
        box-shadow: rgba($warning, .1) 0px 20px 25px -5px, rgb($warning, .2) 0px 10px 10px -5px !important;
    }
}

#toast-container > .toast-error {
    background-color: $danger;
    box-shadow: rgba($danger, .1) 0px 20px 25px -5px, rgb($danger, .2) 0px 10px 10px -5px !important;

    &:before {
        content: "\f00d";
    }

    &:hover {
        box-shadow: rgba($danger, .1) 0px 20px 25px -5px, rgb($danger, .2) 0px 10px 10px -5px !important;
    }
}

#toast-container > .toast-info {
    background-color: $primary;
    box-shadow: rgba($primary, .1) 0px 20px 25px -5px, rgb($primary, .2) 0px 10px 10px -5px !important;

    &:before {
        content: "\f05a";
    }

    &:hover {
        box-shadow: rgba($primary, .1) 0px 20px 25px -5px, rgb($primary, .2) 0px 10px 10px -5px !important;
    }
}

#toast-container > .toast-success {
    background-color: $success;
    box-shadow: rgba($success, .1) 0px 20px 25px -5px, rgb($success, .2) 0px 10px 10px -5px !important;

    &:before {
        content: "\f00c";
    }

    &:hover {
        box-shadow: rgba($success, .1) 0px 20px 25px -5px, rgb($success, .2) 0px 10px 10px -5px !important;
    }
}
