.dispute {}

	.dispute-message {}
	
		.dispute-message-header {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-content: flex-start;
			margin-bottom: 20px;
		}
		
			.dispute-message-header-contact {
				position: relative;
				color: $r2-text-gray;
				
				strong {
					color: $body-color;
				}
			}
			
			.dispute-message-header-timestamp {
				color: $r2-text-gray;
			}
			
		.dispute-message-attachments {
			margin-bottom: 20px;
			display: flex;
			flex-wrap: wrap;
		}
		
			.dispute-message-attachment {
				border: 1px solid $r2-border;
				margin: 0 5px 5px 0;
				border-radius: 12px;
				padding: 0 5px;
				
				&:hover,
				&:focus {
					text-decoration: none;
					background: $r2-table-hover-bg;
				}
			}
			
	.dispute-reply {
		margin-top: 15px;
	}