.planner {
	border: 1px solid $r2-border;
	border-radius: 4px;
	overflow: hidden;
	background: $r2-table-hover-bg;
}

	.planner-header {
		border-bottom: 1px solid $r2-border;
		padding: 12px 24px;
		background: white;
	}
	
		.planner-header-nav {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		
			.planner-header-nav-days {
				display: flex;
				justify-content: center;
				align-items: center;
				
				.btn {
					margin: 0 4px;
				}
			}
	
	.planner-box {
		padding: 24px 24px 0;
		
		
		&:last-child {
			padding-bottom: 24px;
		}
	}
	
			.planner-box--day {
				padding: 24px;
				background: white;
				border-bottom: 1px solid $r2-border;
				
				.planner-box-title {
					color: $r2-sys-red;
				}
			}
			
		.planner-box-title {
			font-size: 18px;
			color: $r2-blue;
		}
		
		.planner-box-subtitle {
			font-weight: normal;
			font-size: 14px;
			color: $r2-blue;
		}
		
	.planner-placeholder {
		border: 1px dashed $r2-border;
		text-align: center;
		padding: 6px 24px;
		border-radius: $border-radius-xl;
		margin-bottom: 4px;
		color: $r2-purple;
	}